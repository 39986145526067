import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png";
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg";
import KruppLogo from "../../assets/logo/Krupp.png";
import SummitMovingLogo from "../../assets/logo/SummitMoving.png";
import logoWeMove from "../../assets/logo/WeMoveNewLogo.png";
import rockstartLogo from "../../assets/logo/rockstartpromovers-logo.png";
const LogoHeader = () => {
  const currentTenant = process.env.REACT_APP_TENANT;
  const prodTenant = process.env.REACT_APP_URL || "";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center" // Centers the logo and text
      width="100vw" // Full width of the viewport
      sx={{ mt: 2 }} // Adds some margin to give spacing from the top
    >
      {currentTenant === "wemove" || currentTenant === "sold" ? (
        <img
          src={logoWeMove}
          alt=""
          style={{ width: "30px", height: "30px" }}
        />
      ) : currentTenant === "turbotenant" ? (
        <img
          src={TurboTenantLogo}
          alt=""
          style={{ width: "150px", height: "44px" }}
        />
      ) : currentTenant === "movebees" ? (
        <img
          src={MoveBeesLogo}
          alt=""
          style={{ width: "240px", height: "66px" }}
        />
      ) : currentTenant === "summitmoving" ? (
        <img
          src={SummitMovingLogo}
          alt=""
          style={{ width: "150px", height: "60px" }}
        />
      ) : currentTenant === "krupp" ? (
        <img
          src={KruppLogo}
          alt=""
          style={{ width: "150px", height: "44px" }}
        />
      ) : currentTenant === "rockstarspromoverspartial" ||
        currentTenant === "rockstarspromoverscomplete" ? (
        <img
          src={rockstartLogo}
          alt=""
          style={{ width: "100px", height: "100px" }}
        />
      ) : currentTenant === "dearmanmoving" ? (
        <img
          src={
            "https://files.youform.com/form-files/30ca37d7-9254-4057-b10e-5ee1919c975a.png"
          }
          alt=""
          style={{ width: "140px", height: "80px" }}
        />
      ) : currentTenant === "safewaymoving" ? (
        <img
          src={
            "https://files.youform.com/form-files/8b70b2ad-9ba8-4299-89c9-35f4844bd14e.png"
          }
          alt=""
          style={{ width: "180px", height: "60px" }}
        />
      ) : currentTenant === "royalmovingco" ? (
        <img
          src={
            "https://files.youform.com/form-files/ba2ef41e-ea69-4dd7-9b37-001d8d6bb89d.JPG"
          }
          alt=""
          style={{ width: "100px", height: "100px", borderRadius: 6 }}
        />
      ) : currentTenant === "harringtonmovers" ? (
        <img
          src={
            "https://files.youform.com/form-files/fa6e25b9-edbf-4a74-8784-e9b292193b35.png"
          }
          alt=""
          style={{ width: "150px", height: "150px", borderRadius: 6 }}
        />
      ) : currentTenant === "elevatedliving" ? (
        <img
          src={
            "https://files.youform.com/form-files/715c61a2-ebbd-4470-9904-de0ec79bd8cf.png"
          }
          alt=""
          style={{ width: "150px", height: "60px", borderRadius: 6 }}
        />
      ) : currentTenant === "harrington-movers" ? (
        <img
          src={
            "https://files.youform.com/form-files/fa6e25b9-edbf-4a74-8784-e9b292193b35.png"
          }
          alt=""
          style={{ width: "150px", height: "150px", borderRadius: 6 }}
        />
      ) : currentTenant === "dependable-movers" ||
        currentTenant === "dependablemovers" ? (
        <img
          src={
            "https://files.youform.com/form-files/1a0f6b4b-cbbd-4608-bc00-b2db42ed4c19.png"
          }
          alt=""
          style={{ width: "150px", height: "120px", borderRadius: 6 }}
        />
      ) : (
        <></>
      )}

      <Typography
        color={theme.palette.primary.main}
        variant="h6"
        style={{ marginLeft: "10px" }}
      >
        {currentTenant === "wemove"||
        currentTenant === "sold"
          ? "WeMove.ai"
          : currentTenant === "turbotenant"
            ? "TurboTenant"
            : currentTenant === "movebees"
              ? "Movebees"
              : currentTenant === "krupp"
                ? "Krupp Moving"
                : currentTenant === "summitmoving"
                  ? "Summit Moving"
                  : currentTenant === "rockstarspromoverspartial" ||
                      currentTenant === "rockstarspromoverscomplete"
                    ? "RockStar Pro Movers"
                    : currentTenant === "dearmanmoving"
                      ? ""
                      : ""}
      </Typography>
    </Stack>
  );
};
export default LogoHeader;
